/* General Styling */
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #f7f8fa; /* Light greyish background */
    color: #333;
  }
  
  button, a {
    font-family: inherit;
    font-size: 1rem;
    text-decoration: none;
    color: #483d8b; /* Blue-purple theme */
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  button:hover, a:hover {
    color: #6a5acd;
  }

  /* NavBar Styling */
.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #b5b6b7fe; /* Light greyish-white */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
    padding: 0 20px;
    z-index: 1000; /* Ensures it's above other elements */
  }
  
  .navbar-container img {
    height: 80%; /* Ensures the image fills the nav height */
    max-width: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Prevents image distortion */
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
  }
  
  .navbar-container .nav-buttons {
    display: flex;
    gap: 10px; /* Space between buttons */
    margin-right: 60px;
  }
  
  .navbar-container button {
    background: none; /* Makes buttons look like plain links */
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: #483d8b; /* Blue-purple text for consistency */
    cursor: pointer;
    transition: color 0.2s ease, transform 0.2s ease;
  }
  
  .navbar-container button:hover {
    color: #6a5acd; /* Lighter blue-purple on hover */
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
/* Home Section Styling */
.me-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    /*background: url('Leonardo_ai.jpg') no-repeat center center;*/
    background-size: cover;
  }

  .me-container h1 {
    font-size: 2.5rem;         /* Larger font size for h1 */
    color: #353566;               /* Darker color for prominence */
    margin-bottom: 40px;       /* Consistent bottom margin */
    max-width: 600px;          /* Same max-width for consistency */
    word-wrap: break-word;     /* Ensures long headings wrap */
    line-height: 1.5;          /* Slightly tighter line-height */
}
  
  .me-container h3 {
    font-size: 1.5rem;
    color: #808080; /* Grey */
    margin-bottom: 40px;
    max-width: 600px; /* Restrict width for word wrapping */
    word-wrap: break-word;
    line-height: 1.5;
  }
  
  .me-container button {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    background: linear-gradient(45deg, #483d8b, #6a5acd); /* Blue-purple gradient */
    color: white;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
  }
  
  .me-container button:hover {
    transform: scale(1.05); /* Slightly enlarges on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Adds a hover shadow */
  }
  
  /* Projects Section Styling */
  .project-container {
    min-height: calc(50vh * 3);
    padding: 50px 20px;
    display: grid;
    gap: 30px;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
    text-align: center; /* Centers text inside h2 and p */
    background-color: #f5f5f5;
  }
  
  .panel {
    display: flex;
    flex-direction: row; /* Ensures image and content are side by side */
    margin-bottom: 20px;
    gap: 10%;
    width: 100%;
    max-width: 1200px;
  }
  
  .panel .image {
    width: 40%;
  }
  
  .panel .image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image is properly cropped and covers its container */
  }
  
  .panel .content {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Aligns content (h2, p, button) vertically */
    align-items: center; /* Centers content horizontally */
    text-align: center; /* Centers text inside h2 and p */
  }
  
  .panel .content h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #483d8b; /* Blue-purple for the heading */
  }
  
  .panel .content p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #555; /* Subtle grey for text */
    line-height: 1.6;
    background: #f0f4ff; /* Light background for better visibility */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for p */
  }
  
  .panel .content button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background: linear-gradient(45deg, #483d8b, #6a5acd); /* Blue-purple gradient */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    max-width: 150px;
  }
  
  .panel .content .button:hover {
    transform: scale(1.05); /* Slightly enlarges on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle hover shadow */
  }
  
  
  .panel .content button {
    font-size: 1rem;
  }
  
  /* Contacts Section Styling */
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    min-height: 100vh;
    background-color: #f5f5f5;
    box-sizing: border-box;
    /*background: url('bg-2.jpg') no-repeat center center;*/
  }
  
  .contact-container h2 {
    font-size: 2rem;
    color: #483d8b;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .contact-container input,
  .contact-container textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-container textarea {
    height: 100px;
    resize: none;
  }
  
  .contact-container button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background: linear-gradient(45deg, #483d8b, #6a5acd);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .contact-container button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container .link {
    margin-top: 20px;
    font-size: 1rem;
    color: #483d8b;
    text-decoration: none;
  }
  
  .contact-container .link:hover {
    text-decoration: underline;
  }
  
  .contact-container .icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .contact-container .icons a {
    width: 30px;
    height: 30px;
    display: inline-block;
  }
  
  .contact-container .icons img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 768px) {
    .project-container {
      /*flex-direction: column;*/
      padding: 20px 10px;
      width: 100%;
      /*align-items: center;*/
    }
  
    .panel {
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }
  
    .panel .image {
      width: 100%; /* Full width on mobile */
    }
  
    .panel .content {
      width: 100%; /* Full width on mobile */
      padding: 0 15px;
    }
  
    .me-container h3 {
      font-size: 1.2rem;
      margin-bottom: 20px;
      padding: 0 10px;
    }
  }
